/* eslint-disable react/prop-types */
import React from 'react';

const defaultState = {
  testID: 'AAAAAAAA',
  date: '1989/01/23',
  changeTestID: () => {}
};

const TestContext = React.createContext(defaultState);

class TestProvider extends React.Component {
  state = {
    testID: ''
  };

  componentDidMount() {
    const testID = JSON.parse(localStorage.getItem('testID'));
    if (testID) {
      this.setState({ testID });
    }
  }

  changeTestID = value => {
    localStorage.setItem('testID', JSON.stringify(value));
    this.setState({ testID: value });
  };

  render() {
    const { children } = this.props;
    const { testID } = this.state;
    return (
      <TestContext.Provider
        value={{
          testID,
          date: '1989/01/23',
          changeTestID: this.changeTestID
        }}
      >
        {children}
      </TestContext.Provider>
    );
  }
}

export default TestContext;

export { TestProvider };
