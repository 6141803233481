// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/6750bc6e/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coverage-dash-js": () => import("/tmp/6750bc6e/src/pages/coverageDash.js" /* webpackChunkName: "component---src-pages-coverage-dash-js" */),
  "component---src-pages-graph-examples-js": () => import("/tmp/6750bc6e/src/pages/graphExamples.js" /* webpackChunkName: "component---src-pages-graph-examples-js" */),
  "component---src-pages-index-js": () => import("/tmp/6750bc6e/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-test-js": () => import("/tmp/6750bc6e/src/pages/listTest.js" /* webpackChunkName: "component---src-pages-list-test-js" */),
  "component---src-pages-login-js": () => import("/tmp/6750bc6e/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

